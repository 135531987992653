import { storageController } from './Controller'

const bookStorage = storageController('favoriteBooks')

const bookStorageListener = new CustomEvent('favorite-books-localstorage-changed', {
  detail: {
    storage: bookStorage.getAll(),
  },
})

function updateFavoriteCounter(): void {
  const hasFavoritesStorage = bookStorage.getAll().length > 0
  const favoriteCounter = document.querySelectorAll('[data-js="FavoriteCounter"]')

  if (favoriteCounter.length > 0) {
    favoriteCounter.forEach((counterEl) => {
      const favoriteCounterHeartWrapper = counterEl.querySelector(
        '[data-js="FavoriteCounterHeartWrap"]'
      )

      if (favoriteCounterHeartWrapper) {
        if (hasFavoritesStorage) {
          favoriteCounterHeartWrapper.classList.remove('hidden')
        } else {
          favoriteCounterHeartWrapper.classList.add('hidden')
        }
      }

      const favoriteCounterNumber = counterEl.querySelector('[data-js="FavoriteCounterNumber"]')
      if (favoriteCounterNumber) {
        favoriteCounterNumber.textContent = bookStorage.getAll().length.toString()
      }

      animateFavoriteUpdate(counterEl as HTMLElement)
    })
  }
}

function animateFavoriteUpdate(element: HTMLElement): void {
  element.classList.add('animating')
  setTimeout(() => {
    element.classList.remove('animating')
  }, 500)
}

export function getAllBooks(): string[] {
  return bookStorage.getAll()
}

export function saveBook(bookId: string): void {
  bookStorage.save(bookId)
  window.dispatchEvent(bookStorageListener)
}

export function removeBook(bookId: string): void {
  bookStorage.remove([bookId])
  window.dispatchEvent(bookStorageListener)
}

export function removeAllBooks(): void {
  getAllBooks().forEach((bookId) => {
    removeFavoriteButtonClass(bookId)
  })

  bookStorage.remove('all')
  window.dispatchEvent(bookStorageListener)
}

function toggleFavoriteButton(element: HTMLElement, bookId: string): void {
  const buttons = document.querySelectorAll(`[data-book-id="${bookId}"]`)

  if (bookStorage.getById(bookId)) {
    removeBook(bookId)
    element.classList.remove('is-favorite')
    buttons.forEach((button) => {
      button.classList.remove('is-favorite')
    })
  } else {
    saveBook(bookId)
    element.classList.add('is-favorite')
    buttons.forEach((button) => {
      button.classList.add('is-favorite')
    })
  }
  animateFavoriteUpdate(element)
  updateFavoriteCounter()
}

function removeFavoriteButtonClass(bookId: string): void {
  const buttons = document.querySelectorAll(`[data-book-id="${bookId}"]`)
  buttons.forEach((button) => {
    button.classList.remove('is-favorite')
  })
}

// Define a set to keep track of initialized buttons, to avoid adding multiple event listeners
const initializedButtons = new Set<Element>()

export default function InitBookFavorites(): void {
  updateFavoriteCounter()

  const favoriteButtons = document.querySelectorAll('[data-js="AddToFavorites"]')
  if (favoriteButtons && favoriteButtons !== null) {
    favoriteButtons.forEach((favoriteButton) => {
      const bookId = favoriteButton.getAttribute('data-book-id')
      if (bookId && !initializedButtons.has(favoriteButton)) {
        if (bookStorage.getById(bookId)) {
          favoriteButton.classList.add('is-favorite')
        }

        favoriteButton.addEventListener('click', () => {
          toggleFavoriteButton(favoriteButton as HTMLElement, bookId)
        })

        initializedButtons.add(favoriteButton)
      }
    })
  }

  const removeFromFavoritesButtons = document.querySelectorAll('[data-js="RemoveFromFavorites"]')
  if (removeFromFavoritesButtons && removeFromFavoritesButtons !== null) {
    removeFromFavoritesButtons.forEach((removeFromFavoritesButton) => {
      const bookId = removeFromFavoritesButton.getAttribute('data-book-id')
      if (bookId && !initializedButtons.has(removeFromFavoritesButton)) {
        removeFromFavoritesButton.addEventListener('click', () => {
          removeBook(bookId)
          removeFavoriteButtonClass(bookId)
          updateFavoriteCounter()
        })

        initializedButtons.add(removeFromFavoritesButton)
      }
    })
  }
}
