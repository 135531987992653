// Our main CSS
import '../css/app.css'

/**
 * 🦄: Now do your magic.
 */

import LazyLoad from 'vanilla-lazyload'

import './Favorites/index'
import initMobileMenu from './components/MobileMenu'

initMobileMenu()

const lazyLoad = new LazyLoad({
  elements_selector: '.lazy',
  threshold: 25,
})
window.lazyload = lazyLoad

const BookSlider = document.querySelectorAll<HTMLElement>('[data-js="BookSlider"]')
if (BookSlider && BookSlider !== null) {
  import('./components/BookSlider')
    .then((module) => {
      module.default(BookSlider as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const BookFilter = document.querySelector<HTMLElement>('[data-js="BookFilter"]')
if (BookFilter && BookFilter !== null) {
  import('./BookFilter/index')
    .then((module) => {
      module.default(BookFilter)
    })
    .catch((err) => {
      console.log(err)
    })
}

const BookFavoriteList = document.querySelector<HTMLElement>('[data-js="BookFavoriteList"]')
if (BookFavoriteList && BookFavoriteList !== null) {
  import('./BookFavoriteList')
    .then((module) => {
      module.default(BookFavoriteList)
    })
    .catch((err) => {
      console.log(err)
    })
}

const NewsFilter = document.querySelector<HTMLElement>('[data-js="NewsFilter"]')
if (NewsFilter && NewsFilter !== null) {
  import('./NewsFilter/index')
    .then((module) => {
      module.default(NewsFilter)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AgeSelectorTakeover = document.querySelector<HTMLElement>('[data-js="AgeSelectorTakeover"]')
if (AgeSelectorTakeover && AgeSelectorTakeover !== null) {
  import('./AgeSelectorTakeover')
    .then((module) => {
      module.default(AgeSelectorTakeover)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AgeSelector = document.querySelector<HTMLElement>('[data-js="AgeSelector"]')
if (AgeSelector && AgeSelector !== null) {
  import('./AgeSelector')
    .then((module) => {
      module.default(AgeSelector)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VoteTakeover = document.querySelector<HTMLElement>('[data-js="VoteTakeover"]')
const voteButtons = document.querySelectorAll<HTMLElement>('[data-js="VoteButton"]')

let VoteTakeoverModule: any = null
let isModuleLoaded = false

if (VoteTakeover && voteButtons) {
  voteButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const bookId = button.getAttribute('data-book-id')
      const bookTitle = button.getAttribute('data-book-title')

      if (bookId && bookTitle) {
        if (!isModuleLoaded) {
          import('./VoteTakeover')
            .then((module) => {
              VoteTakeoverModule = module
              isModuleLoaded = true
              VoteTakeoverModule.default(VoteTakeover, bookId, bookTitle, true)
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          VoteTakeoverModule.default(VoteTakeover, bookId, bookTitle, true)
        }
      }
    })
  })
}
