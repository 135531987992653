interface IStorageHandler {
  save(dataId: string): void
  getAll(): string[]
  getById(dataId: string): string | null
  remove(dataIds: string[] | 'all'): void
}

function storageController(storageKey: string): IStorageHandler {
  function save(dataId: string): void {
    const data: string[] = JSON.parse(localStorage.getItem(storageKey) || '[]')
    data.push(dataId)
    localStorage.setItem(storageKey, JSON.stringify(data))
  }

  function getAll(): string[] {
    return JSON.parse(localStorage.getItem(storageKey) || '[]')
  }

  function getById(dataId: string): string | null {
    const data: string[] = this.getAll()
    const foundItem = data.find((id) => id === dataId)
    return foundItem || null
  }

  function remove(dataIds: string[] | 'all'): void {
    if (dataIds === 'all') {
      localStorage.setItem(storageKey, '[]')
    } else {
      const data: string[] = this.getAll()
      dataIds.forEach((dataId) => {
        const index = data.indexOf(dataId)
        if (index !== -1) {
          data.splice(index, 1)
        }
      })
      localStorage.setItem(storageKey, JSON.stringify(data))
    }
  }

  return {
    save,
    getAll,
    getById,
    remove,
  }
}

export { IStorageHandler, storageController }
