function toggleMobileMenu() {
  document.body.classList.toggle('site-mobile-menu-open')
}

function initMobileMenu() {
  document
    .querySelector('[data-js="SiteMobileMenuToggle"]')
    ?.addEventListener('click', toggleMobileMenu)
}

export default initMobileMenu
